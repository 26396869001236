import React, {useEffect, useState} from 'react'
import '../App.css';
import '../css/body.css';
import { Outlet, Link } from "react-router-dom";
import { Col, Row,  } from 'antd';
import Spotify from '../components/frames/Spotify'
import Footer from '../components/Footer';
import certificaciones from '../assets/images/premioESR.jpg'

import {  mazatlan, mochis } from '../assets/base64'
import pichilingue from '../assets/images/pichilingue.webp'

import videoRSE from '../assets/video/rse_sea.mp4'
import RSElogo from '../assets/images/LOGOS-RSE.png';
import RSEImg from '../assets/images/rseImg.webp'
import facebook from '../assets/images/spotify/facebook.svg';
import twitter from '../assets/images/spotify/twitter.svg';
import instagram from '../assets/images/spotify/instagram.svg';
import youtube from '../assets/images/spotify/youtube.svg';

import NoticiaBody from '../components/NoticiaBody';
import ruta from '../assets/images/ruta.png'
import tarifa from '../assets/images/tarifa.png'
import PromocionesHorizontal from '../components/Promociones_horizontal';
import Destinos_horizontal from '../components/Destinos_horizontal';
import Valoracion from './Valoracion';
import { useTranslation } from 'react-i18next';
import puroBajaSur from '../assets/images/promociones/promocion_ninos-cuadrado.webp'

import transportamas from '../assets/images/promociones/promo-abordo.webp';
import promoMascota from '../assets/images/promociones/inapam.webp';

export default function BodyContent() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const { t } = useTranslation('home');
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  return (
        <>
        
        <div class="d-none d-sm-none d-md-block">
            <section className="container" id='promociones'>
            
                <div className='promociones_title_home '>
                    <h2>{t('text1')}</h2>
                    <Link to="/promociones">{t('btn1')}<i class="fi fi-sr-angle-small-right"></i></Link>
                </div>
                <div className='promociones'>                                        
                  
                    <div className='box_content_card_promo'>
                        <Link to="/promo-abordo">
                            <div className='box_group_card_promo' style={{ backgroundImage: `url(${transportamas})` }}>
                              
                            </div>
                        </Link>
                    </div>
                    
                   

                    <div className='box_content_card_promo'>
                        <Link to="/promo-ninos">
                            <div className='box_group_card_promo' style={{ backgroundImage: `url(${puroBajaSur})` }}>
                                
                                
                            </div>
                        </Link>
                    </div>      
                    <div className='box_content_card_promo'>
                        <Link to="/promo-adulto-mayor">
                            <div className='box_group_card_promo' style={{ backgroundImage: `url(${promoMascota})` }}>
                                
                                
                            </div>
                        </Link>
                    </div>                                                        
                </div> 
            </section>
        </div>           
       
        <section class="d-block d-sm-block d-md-none">
            <div className="container" id='promociones'>                                
                <PromocionesHorizontal />                         
            </div>
        </section>

      {/* Rutas, Horarios y Tarifas */}
      <section className='cta_body'>
        <div className='container'>
            <div className='cta_container_body'>
                <div className='cta_box_body ruta-horario'>
                    <div className='cta_content_body'>
                        <div className='cta_img_body'><div className='cta_box_img_body'><img src={ruta}  alt='ruta y horarios'/></div></div>
                        <div className='cta_info_body'>                            
                            <div className='cta_text_body'>
                                <h2>{t('text2')}</h2>
                                <p>{t('text3')}</p>
                            </div>
                            <div className='cta_btn_body'>                                
                                <Link to="/rutas_horario">{t('btn1')} </Link>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className='cta_box_body tarifa'>
                    <div className='cta_content_body'>
                        <div className='cta_img_body'><div className='cta_box_img_body'><img src={tarifa} alt='tarifa baja ferries'/></div></div>
                        <div className='cta_info_body'>
                            <div className='cta_text_body'>
                                <h2>{t('text4')}</h2>
                                <p>{t('text5')}</p>
                            </div>                            
                            <div className='cta_btn_body'>
                                <Link to="/tarifas">{t('btn1')} </Link>
                            </div>
                        </div>
                    </div>                   
                </div>                
            </div>
        </div>
      </section>
     
        
       {/*DESTINOS*/}
       <section className="d-none d-sm-none d-md-block">
        <div className='container'>
            <div className='container_destinos'>            
                <div className='box_destinos'>
                    <h2>{t('text6')}</h2>
                    <p>{t('text7')}</p>
                </div>
                <Row>
                    <Col span={8}>
                        <div className='content_destino1'>
                            <Link to="/destino-la-paz"> <div className="cardDestinos">
                                    <img src={pichilingue} alt="pichilingue" className='imgDestino' />                        
                                    <div className="textDestino">
                                        <h4>La Paz, Baja California Sur.</h4> 
                                        <p>{t('text8')}</p> 
                                    </div>
                                </div> 
                            </Link>                           
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className='content_destino2'>
                        <Link to="/destino-mazatlan">
                            <div className="cardDestinos">
                                <img src={mazatlan} alt="mazatlan" className='imgDestino' />                        
                                <div className="textDestino">
                                    <h4>Mazatlán, Sinaloa.</h4> 
                                    <p>{t('text9')}</p> 
                                </div>
                            </div>

                        </Link> 
                            
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className='content_destino3'> 
                            <Link to="/destino-los-mochis">
                                <div className="cardDestinos">
                                    <img src={mochis} alt="mochis" className='imgDestino' />                        
                                    <div className="textDestino">
                                        <h4>Los Mochis, Sinaloa.</h4> 
                                        <p>{t('text10')}</p> 
                                    </div>
                                </div>
                            </Link> 
                            
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
        </section>
        <section className="d-block d-sm-block d-md-none">
            <section className="container" id='destinos'>                                
                <Destinos_horizontal />                         
            </section>
        </section>

         {/*RSE*/}

        {/*<section className='rse_section'>
       {/} <Link to="/">/}

            <div className='videoRSE'>
                <video muted loop autoPlay={true} width="750" height="500" >
                    <source src={videoRSE} type="video/mp4" /> 
                </video>
                <div className='RSEImg'>                
                    <img src={RSEImg} alt='baja ferries hero' />                                     
                </div>
                               
                <img src={RSElogo} alt="RSElogo" className='RSElogo' />   

                <div className='text_body_rse'>
                    <p>Nos esforzamos por integrar prácticas sostenibles en todas nuestras operaciones, garantizando un impacto positivo en el medio ambiente y las comunidades que servimos.</p>
                    <p>Muy pronto conocerás más sobre nuestras iniciativas y cómo estamos trabajando para preservar el planeta para las futuras generaciones.</p>
                </div>             
                 
            </div>
           {/} </Link>  /}
            
        </section>*/}        
        
        <section className='rse_section'>
            <div className='videoRSE'>                
                {!isMobile && (
                   <video muted loop autoPlay={true} width="750" height="500" >
                    <source src={videoRSE} type="video/mp4" /> 
                </video>
                ) }
                    
                <div className="content-text-rse">
                    <div className='container'>
                        <div className='container-text-rse'>
                            <img src={RSElogo} alt="RSElogo" className="RSElogo" />
                            <p>{t('text11')}</p>
                            <p>{t('text12')}</p>
                        </div>
                    </div>
                </div>      
            </div>          
        </section>
       
        <div className='container'>
            <div className='RSEImg'>                
                <img src={RSEImg} alt='baja ferries hero' />
                <div className='content-text-rse-mobile'>
                    <img src={RSElogo} alt="RSElogo" className="RSElogo" />
                    <p>{t('text11')}</p>
                    <p>{t('text12')}</p>
                </div>
            </div>
        </div> 

        {/*NOTICIAS*/}

        <section>
            <div className='container'>
                <div className='box_noticias'>
                    <h2>{t('text13')}</h2>
                    <p>{t('text14')} </p>
                    <div style={{ padding: '1rem 0rem', marginBottom: '1rem'}}>
                        <Link to="/noticias"> 
                        {t('btn3')}                   
                        </Link>
                    </div>  
                </div>
            
                <NoticiaBody /> 
    
            
          </div>
        </section>
        

      
      

        {/* SPOTIFY */}
        <section className='spotify_section'>            
            <div className='container'>
                <div className='box_spotify'>                                                                                   
                    <div className='list_icon_spotify'>
                        <div className='title_spotify'>
                            <h3>{t('text15')}</h3>
                        </div>
                        <ul>
                            <li>
                                <a href="https://www.facebook.com/bajaferriesoficial/" rel='noopener noreferrer' target="_blank"><img src={facebook} alt='facebook oficial bajaferries' className='bg_spotify' /> </a>                                                                            
                            </li>
                            <li>                                        
                                <a href="https://x.com/bajaferries" rel='noopener noreferrer' target="_blank"><img src={twitter} alt='twitter oficial bajaferries' className='bg_spotify' /> </a>                                      
                            </li>
                            <li>
                                <a href="https://www.instagram.com/bajaferriesoficial/" rel='noopener noreferrer' target="_blank"><img src={instagram} alt='instagram oficial bajaferries' className='bg_spotify' /> </a>                                                                                
                            </li>
                            <li>
                                <a href="https://www.youtube.com/@bajaferries2914" rel='noopener noreferrer' target="_blank"><img src={youtube} alt='youtube oficial bajaferries' className='bg_spotify' /></a>                                                                                
                            </li>
                            
                        </ul>
                    </div>                
                    <div className='content_frame_spotify'>
                        <div className='frame_spotify'>
                            <Spotify />
                        </div>
                    </div>
                </div>
            </div>
        </section>


        {/*CERTIFICACIONES
        <section>
            <div className='container'>
                <Carousel logos={logos} />
            </div>
        </section>
        */}
        <section>
            <div className='container'>
                <div className='certificaciones_body'>
                    <div className='certificaciones_box_body'>
                        <img src={certificaciones} alt='cerfificaciones de baja ferries' />
                    </div>
                    <div className='certificaciones_box_body text_certificaciones'>
                        <h2>{t('text16')}</h2>
                        <p>
                            {/*Las certificaciones que hemos obtenido son el resultado de nuestro compromiso continuo con la excelencia, son un sello de aprobación que demuestra que cumplimos con los estándares más rigurosos en nuestra industria. Queremos que te sientas seguro al utilizar nuestros servicios.*/}
                            {t('text17')} 
                        </p>
                        <div className='btn_certificaciones_box'>
                            <Link to="/certificaciones">
                            {t('btn2')} <i class="fi fi-rr-arrow-small-right"></i>
                            </Link>                      
                        </div>
                    </div>
                </div>
                
            </div>
        </section>

         {/*FOTER*/}
        <Footer />

        <Valoracion />
    
        <Outlet />
        </>
  )
}
 